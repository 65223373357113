<template>
  <v-theme-provider light>
    <base-section id="get-started-section" class="text-center" space="86">
      <base-section-heading title="Explore ProInsight" color="blue">Browse through thousands of curated professionals</base-section-heading>

      <v-responsive class="d-flex align-center mx-auto mb-4" height="100%" max-width="700" width="100%">
        <v-container fluid>
          <v-row>
            <v-col>
              <v-btn color="primary" class="mr-4 font-weight-bold v-btn v-btn--contained theme--light v-size--x-large _next" @click="registerAction()">
                {{ registerbuttonstring }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
    </base-section>
  </v-theme-provider>
</template>

<script>
import { EnvX } from '@/environments/EnvX.ts'

export default {
  name: 'Researcher',
  data: () => ({
    registerbuttonstring: 'Create a researcher account'
  }),

  methods: {
    // Redirection **must** be done by Vue. AuthService is not a navigation service
    redirectToRouteHandler(redirectRoute) {
      EnvX.assert(redirectRoute != null, '🕵️‍♂️ redirectRoute')
      if (redirectRoute) {
        EnvX.log('Redirecting to route «' + redirectRoute + '»')
        this.$router.push({
          name: redirectRoute,
          params: {}
        })
      }
    },

    registerAction() {
      // On minimal info submission
      // @see authConfig:registrationRequest
      const statusRoutes = {
        successRoute: 'subscriberesearcherroute',
        failureRoute: 'researcherroute'
      }

      this.$AuthService.register(this.redirectToRouteHandler, statusRoutes)
    }
  }
}
</script>

<style scoped>
._next {
  position: relative;
  top: +14px;
  width: 100%;
}
</style>
